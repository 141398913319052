import { queryOptions } from "@tanstack/vue-query";
import camelize from "camelcase-keys";
import type { Campaign } from "~/lib/campaigns";
import { useAccessToken } from "./composables";
import decamelize from "decamelize-keys";
import type { AdditionalMetrics, Metrics, NFCType } from "../nfc/types";

export const memberQueries = {
  all: () => ["members"] as const,
  self: () =>
    queryOptions({
      queryKey: [...memberQueries.all(), "self"] as const,
      queryFn: async () => {
        const token = useAccessToken();

        if (!token.value) {
          return null;
        }

        const api = useApi();
        try {
          const data = await api<SelfResponse>("/v1/members/self", {
            method: "GET",
          });
          return camelize(data, { deep: true });
        } catch {
          return null;
        }
      },
      staleTime: 1000 * 60 * 60,
    }),
  details: () => [...memberQueries.all(), "detail"] as const,
  detail: (memberId: MaybeRefOrGetter<number>) =>
    queryOptions({
      queryKey: [...memberQueries.details(), toValue(memberId)] as const,
      queryFn: async () => {
        const api = useApi();
        const data = await api<DetailResponse>(
          `/v1/members/${toValue(memberId)}`,
          {
            method: "GET",
          },
        );

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 5,
    }),
  asGuest: (memberId: MaybeRefOrGetter<number>) =>
    queryOptions({
      queryKey: [...memberQueries.detail(memberId).queryKey, "asGuest"],
      queryFn: async () => {
        const api = useApi();
        const data = await api<DetailResponse>(
          `/v1/members/asGuest/${toValue(memberId)}`,
          {
            method: "GET",
          },
        );

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 5,
    }),
  family: (memberId: MaybeRefOrGetter<number>) =>
    queryOptions({
      queryKey: [...memberQueries.detail(memberId).queryKey, "family"],
      queryFn: async () => {
        const api = useApi();
        const data = await api<FamilyResponse>(
          `/v1/members/${toValue(memberId)}/family`,
          {
            method: "GET",
          },
        );

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 10,
    }),
  datastore: (memberId: MaybeRefOrGetter<number>) =>
    queryOptions({
      queryKey: [...memberQueries.detail(memberId).queryKey, "datastore"],
      queryFn: async () => {
        const api = useApi();
        const data = await api<Record<string, unknown>>(
          `/v1/members/${toValue(memberId)}/datastore`,
          {
            method: "GET",
          },
        );

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 10,
    }),

  shirts: (memberId: MaybeRefOrGetter<number>) => ({
    queryKey: [...memberQueries.detail(memberId).queryKey, "shirts"] as const,
    queryFn: async () => {
      const api = useApi();
      const data = await api<ShirtsResponse[]>(
        `/v1/members/${toValue(memberId)}/shirts`,
      );
      return camelize(data, { deep: true });
    },
  }),

  impactInDate: (
    params: MaybeRefOrGetter<{
      memberId: number;
      startDate: string;
      endDate: string;
    }>,
  ) =>
    queryOptions({
      queryKey: [
        ...memberQueries.detail(() => toValue(params).memberId).queryKey,
        "impactInDate",
        {
          startDate: toValue(params).startDate,
          endDate: toValue(params).endDate,
        },
      ],
      queryFn: async () => {
        const api = useApi();
        const data = await api<ImpactInDateResponse[]>(
          `/v1/members/impactInDate`,
          {
            params: decamelize(toValue(params)),
          },
        );
        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 10,
    }),
  familyInDate: (
    params: MaybeRefOrGetter<{
      memberId: number;
      startDate?: string;
      endDate?: string;
    }>,
  ) =>
    queryOptions({
      queryKey: [
        ...memberQueries.detail(toValue(params).memberId).queryKey,
        "familyInDate",
        {
          startDate: toValue(params).startDate,
          endDate: toValue(params).endDate,
        },
      ] as const,
      queryFn: async () => {
        const api = useApi();
        const data = await api<FamilyInDateResponse>(
          `/v1/members/${toValue(params).memberId}/familyInDate`,
          {
            query: {
              start_date: toValue(params).startDate,
              end_date: toValue(params).endDate,
            },
          },
        );
        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 10,
    }),
};

type SelfResponse = {
  id: number;
  created_at: string;
  updated_at: string;
  parent_id: number | null;
  status: string;
  type: "member" | "partner";
  email: string;
  points: number;
  referrals: number;
  first_name: string;
  last_name: string;
  street: string | null;
  city: string;
  state: string;
  postal_code: string | null;
  country: string;
  referral_code: string;
  avatar_path: string | null;
  message: string | null;
  permissions: string[];
  deleted_at: string | null;
  leaderboards: {
    [key in "global" | "country" | "state" | "city"]: {
      rank: number;
      id: number;
      first_name: string;
      last_name: string;
      points: number;
      referrals: number;
      created_at: string;
      city: string;
      state: string;
      country: string;
      partner_slug?: string;
      partner_name?: string;
    }[];
  };
  ranks: {
    leaderboard: "member" | "partner";
    city: number;
    state: number;
    country: number;
    global: number;
  };
  level: string;
  avatar_url: string | null;
  partner: {
    id: number;
    created_at: string;
    updated_at: string;
    member_id: number;
    name: string;
    slug: string;
  } | null;
  metrics: { campaign_id: Campaign; points: number; amount: number }[];
};

type DetailResponse = {
  id: number;
  created_at: string;
  updated_at: string;
  parent_id: number | null;
  status: string;
  type: "member" | "partner";
  points: number;
  referrals: number;
  first_name: string;
  last_name: string;
  city: string;
  state: string;
  country: string;
  referral_code: string;
  avatar_path: string | null;
  message: string | null;
  permissions: string[];
  deleted_at: string | null;
  leaderboards: {
    [key in "global" | "country" | "state" | "city"]: {
      rank: number;
      id: number;
      first_name: string;
      last_name: string;
      points: number;
      referrals: number;
      created_at: string;
      city: string;
      state: string;
      country: string;
    }[];
  };
  ranks: {
    leaderboard: "member" | "partner";
    city: number;
    state: number;
    country: number;
    global: number;
  };
  level: string;
  avatar_url: string | null;
  metrics: { campaign_id: Campaign; points: number; amount: number }[];
  partner: {
    id: number;
    created_at: string;
    updated_at: string;
    member_id: number;
    name: string;
    slug: string;
  } | null;
};

type FamilyResponse = {
  id: number;
  points: number;
  referrals: number;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  metrics: {
    campaign_id: Campaign;
    points: number;
    amount: number;
  }[];
  family: FamilyResponse[];
};
type ImpactInDateResponse = {
  campaign_id: Campaign;
  impact_value: number;
  total_amount: number;
};

type FamilyInDateResponse = {
  count: number;
};

type ShirtsResponse = {
  id: number;
  member_id: number;
  campaign_id: number;
  dollar_value: number;
  type: NFCType;
  upgrade: {
    rarity: "ultra-rare" | "rare" | "epic";
    dollar_amount: number;
  } | null;
  position: number;
  created_at: string;
  updated_at: string;
  nfc_id: string;
  member_name: string;
  own_metrics: Record<string, Metrics>;
  additional_metrics: Record<string, AdditionalMetrics>;
  progress: {
    level: number;
    experience_earned_in_current_level: number;
    progress_to_next_level: number;
    total_experience: number;
    total_experience_to_next_level: number;
  };
};
