import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0_m_crabz5mgo2rlkihzi6xzp77uui/node_modules/nuxt/dist/pages/runtime/validate.js";
import referral_45global from "/vercel/path0/middleware/referral.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0_m_crabz5mgo2rlkihzi6xzp77uui/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  referral_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/vercel/path0/middleware/authenticated.ts")
}