
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index8TnTrG1RLdezOSU25M4mZcfRoygosPx29K_45pj23ghrsMeta } from "/vercel/path0/pages/about/index.vue?macro=true";
import { default as anniversaryJxDx_VjKiHwrKcczmTcyKALoGEIAH_45RK9FV158kBQQ0Meta } from "/vercel/path0/pages/anniversary.vue?macro=true";
import { default as auth7OMn_45RoyumEofPwA4BlS_45YlbPnH7bLXKV0RJxD4jYUkMeta } from "/vercel/path0/pages/auth.vue?macro=true";
import { default as indexA3gAnHil6NAbO9fQFT05eCXtOspgdDuHHS7lEwCry_45IMeta } from "/vercel/path0/pages/campaigns/index.vue?macro=true";
import { default as indexuyBHRlkQVd2eV_8YkDhcsSZFm3Wz_45In7CU_BXWAaFdoMeta } from "/vercel/path0/pages/campaigns/ocean-voyages-institute/index.vue?macro=true";
import { default as indexF6o8mTNZR_45XyVz7wm4HLeSeXq2p7ZZyCyFwZ7ecLnkwMeta } from "/vercel/path0/pages/campaigns/plastic-fischer/index.vue?macro=true";
import { default as indexNl4mlJgQEPALeTfb7Eq9dpBir8jLm_57tMscgytuwpQMeta } from "/vercel/path0/pages/campaigns/reefgen/index.vue?macro=true";
import { default as indexlTu6PwPJL4jud_4517fxH_r4m7XF7fyFC7EzwVNYV6jfEMeta } from "/vercel/path0/pages/campaigns/trees-for-the-future/index.vue?macro=true";
import { default as index1TtOokqi_WyMtMfBsFzcEOOQdgvodiiA_aHAnHoAyc4Meta } from "/vercel/path0/pages/campaigns/yellow-rooms/index.vue?macro=true";
import { default as expowestjMRUuxR0WTifrQJHBsTxfj2XKQ9LFC5lDgKaFnO9P60Meta } from "/vercel/path0/pages/expowest.vue?macro=true";
import { default as faqjjKwHwl3gHPshJy6Fwl0mtVaKy4g6wmpINdRy4T3GwoMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as FAQiTVnsmgfzIZHG5IQQcQjEw6Ckb_AE35_Wp0qiRixc4YMeta } from "/vercel/path0/pages/for-business/FAQ.vue?macro=true";
import { default as HeroHgEcrX5BfPxWZU0XqI_PZJsoDBqTY4drIlCtus_4599FwMeta } from "/vercel/path0/pages/for-business/Hero.vue?macro=true";
import { default as indexMoC_vnu8fag_45gEDubrnFurSn4futojEOY4D2UbZ4FBQMeta } from "/vercel/path0/pages/for-business/index.vue?macro=true";
import { default as PartnersnKfIinUkqW0GeNBXfsCQDJehbld87yVaz2CLBVdhfwcMeta } from "/vercel/path0/pages/for-business/Partners.vue?macro=true";
import { default as PricingQaQn4X7wOoQTPGVUS4AJKX7OmT1ZYSTxd4ZObgOeBF4Meta } from "/vercel/path0/pages/for-business/Pricing.vue?macro=true";
import { default as Reviews0nMXW6xNGkj0WV0JK833GZzOkf3SSAVkc_OEZbEJwDEMeta } from "/vercel/path0/pages/for-business/Reviews.vue?macro=true";
import { default as sign_45up3OgRvvKN_4502KlXPM96ZcUNxrSFlY2nQS9DkEdZyICYUMeta } from "/vercel/path0/pages/for-business/sign-up.vue?macro=true";
import { default as Step1b8AGSiUFvJLqV9mJz9fG1HKVrUuUqk8JK_45q4HF56EKEMeta } from "/vercel/path0/pages/for-business/Step1.vue?macro=true";
import { default as Step2fjnfRuij5UJLGYELumKbaGPHQaIA6COogs3DqrJXQZQMeta } from "/vercel/path0/pages/for-business/Step2.vue?macro=true";
import { default as Step3kksiwSENLBt6tGS1efEWkW6wqY1zd6rhbH2bOz8jkGsMeta } from "/vercel/path0/pages/for-business/Step3.vue?macro=true";
import { default as Step4DnMRxUXDvELck7tOU0jdjXlGXAqhiPgSGw8lVYlyKPwMeta } from "/vercel/path0/pages/for-business/Step4.vue?macro=true";
import { default as TreeCounter1vDcMEQfmnY76KMBrFh3I5HgU7_5cbyAfMKC4ifOhSEMeta } from "/vercel/path0/pages/for-business/TreeCounter.vue?macro=true";
import { default as VettedBy9qO5MDN6LK_eVnCI4bOsk_Oy1WrzMwm6bTE6T9oKiqEMeta } from "/vercel/path0/pages/for-business/VettedBy.vue?macro=true";
import { default as forgot_45passwordYJWD0lcAfYaJTH90StowtOH8csR4uDHd8I_HNp6CgXIMeta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as redeemsdjaTQH3bMFg6gUvS0n8IyXYKYm33p4_BJ3V81QE73EMeta } from "/vercel/path0/pages/gift/redeem.vue?macro=true";
import { default as signupqntZ9tkIFeCR_ugKwjQRxUi7toQXWEnYCqGXVvXOSaQMeta } from "/vercel/path0/pages/gift/signup.vue?macro=true";
import { default as CardBQvHONtvmR0ROSRrF5luqP_2wv6aS0HOqVym8_45UOq_MMeta } from "/vercel/path0/pages/how-it-works/Card.vue?macro=true";
import { default as CarouselCardpsvzcn0gpGB_g7HLFi7POLSKsoaM_45xeRLUdleoYokTkMeta } from "/vercel/path0/pages/how-it-works/CarouselCard.vue?macro=true";
import { default as FutureShiftC7ZSAIPIxrdGQbDemG0c9INYbQbxTgxMehsfUhZwMx4Meta } from "/vercel/path0/pages/how-it-works/FutureShift.vue?macro=true";
import { default as GlobalImpact98V3eBU4ptktLvrfVJq4Lk_dIpUUZfekI0F3yUnRcSMMeta } from "/vercel/path0/pages/how-it-works/GlobalImpact.vue?macro=true";
import { default as HeroSectionSBNeQB0zxVEuvZwgi0TYbVAhiJ4Y3gF41CqycXQ7CGEMeta } from "/vercel/path0/pages/how-it-works/HeroSection.vue?macro=true";
import { default as ImpactStoriesE1yqBPKYUhFsQOjviHmJccgL5JRFyOsCjW1dmFloVroMeta } from "/vercel/path0/pages/how-it-works/ImpactStories.vue?macro=true";
import { default as indexGN1UN4R5tVaJzoFiI4pmNVbo1XjkINj8foPCJHvC3aIMeta } from "/vercel/path0/pages/how-it-works/index.vue?macro=true";
import { default as InspireGiveWin7_45PE03CTaClOm1IFJThaa3CcbvQN_45W3T2I5LtZ_uiGkMeta } from "/vercel/path0/pages/how-it-works/InspireGiveWin.vue?macro=true";
import { default as MicroChangezB0O9QDBAqsZIRSKCtP0nMq90DRy0wcNVLQJK_45ASbj0Meta } from "/vercel/path0/pages/how-it-works/MicroChange.vue?macro=true";
import { default as ScalingHopea_6Pmra7_5Tyh8bjKMrdZ3czSH01ZwPjmDY7dNBUC4sMeta } from "/vercel/path0/pages/how-it-works/ScalingHope.vue?macro=true";
import { default as TrackImpactdU_45JHWcU6dq5hQqaWflZl1hZLDmABAZEV9uJQ_45zNmHUMeta } from "/vercel/path0/pages/how-it-works/TrackImpact.vue?macro=true";
import { default as CallToActionWHs1LZuXxhXi68sEEVy6PAWCcDYTnXXUSUCjp_rXalwMeta } from "/vercel/path0/pages/humanitys-checklist/CallToAction.vue?macro=true";
import { default as Checklistx1CrD30vUEK9muAuGzxHIMgLzXHptFs05G1578ULoBoMeta } from "/vercel/path0/pages/humanitys-checklist/Checklist.vue?macro=true";
import { default as DisplayTitlevaVMtf2JEoOWo84IQFBxYMWyz5Z2VYarmO3ZcuMev5UMeta } from "/vercel/path0/pages/humanitys-checklist/DisplayTitle.vue?macro=true";
import { default as Goals3rsHOs9voV6tv64t0Ldm5pMLkz_uZJmTixCcwNQEviEMeta } from "/vercel/path0/pages/humanitys-checklist/Goals.vue?macro=true";
import { default as indexyDc6CGcH_45ORd3v8tfpTRDoeaX61_45vgJyZP0Bt_nkkvwMeta } from "/vercel/path0/pages/humanitys-checklist/index.vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as joinlzZNx4kYUvWvSr9GzAn4W1_45yBETs9NGnIIisdjZ5xqkMeta } from "/vercel/path0/pages/join.vue?macro=true";
import { default as loginyiubrZraL9EDD3J38Z_45ftLpCUym9OoYm1Hb9453rWnMMeta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as logouta5LfroDscl83QSOVnBg1WHUyENO_v5V_454taSGE0odUIMeta } from "/vercel/path0/pages/logout.vue?macro=true";
import { default as _91id_93gbXUxvXgRSAznUYatWjGYow5bg2E_45VeSbgxmsZoxIfIMeta } from "/vercel/path0/pages/members/[id].vue?macro=true";
import { default as neckerpickleball2ng3NtrbniHlHbUsLhi9CS_RbnOKTVT8lpo4_45I3YrnAMeta } from "/vercel/path0/pages/neckerpickleball.vue?macro=true";
import { default as confirmationFoYFwl8TvRoaM_45Y2rqLqlblmaJyCX6NZKFmbtX8pPj0Meta } from "/vercel/path0/pages/nfc-shirt/[id]/confirmation.vue?macro=true";
import { default as donate4odST6TbriJToV88UoEFzdCHFALIHYi84IAg5WcPe7EMeta } from "/vercel/path0/pages/nfc-shirt/[id]/donate.vue?macro=true";
import { default as index7Tropa8ghn8Yl_45fkarAHYijZiBgBOrqkyZKiYXhj_45mcMeta } from "/vercel/path0/pages/nfc-shirt/[id]/index.vue?macro=true";
import { default as loginDOSqWjfZwENiMtTjQVMg_H8K2RRfLmYIPoL_iRWh8Z4Meta } from "/vercel/path0/pages/nfc-shirt/[id]/login.vue?macro=true";
import { default as signupqIyeI3v14440z9N9vRWVOrfEc82wfbQtRmuOvt7alsQMeta } from "/vercel/path0/pages/nfc-shirt/[id]/signup.vue?macro=true";
import { default as successyE3yfYgiQgS_sDK2aHAidirU8Bfa_VwETmGCThXASwUMeta } from "/vercel/path0/pages/nfc-shirt/[id]/success.vue?macro=true";
import { default as _91id_93yMyoCFo1CoQRR1MapoYViqREYKRc0PECMHa2TP1M180Meta } from "/vercel/path0/pages/nfc-shirt/[id].vue?macro=true";
import { default as indexZq70BDNEVS1og0b82ZIgFSllXRnghRGdGCLz6GnKxMAMeta } from "/vercel/path0/pages/nfc-shirt/index.vue?macro=true";
import { default as _91slug_930Dzq7DThBr5bZSi0wlDP_skHEdEw_45IVEmbJE_58abJcMeta } from "/vercel/path0/pages/partners/[slug].vue?macro=true";
import { default as password_45recoveryPPK_EskhJGaTpA07Auw_45qflGf7mWcD1v4DeC4bwIoOAMeta } from "/vercel/path0/pages/password-recovery.vue?macro=true";
import { default as indexrJwxQCynhxpb3nvssQCtS1bmH8PjRlT9veyZZLlYGwsMeta } from "/vercel/path0/pages/profile/index.vue?macro=true";
import { default as sign_45upAJio_5B8Q3uHds_45pvV9dCL_rHlF1ZjrGCGXnPECopOIMeta } from "/vercel/path0/pages/sign-up.vue?macro=true";
import { default as indexGecWXILhcAlhsUPVDUUJMTydrWw5CWRsfXkHolLrRJwMeta } from "/vercel/path0/pages/theory-of-giving/index.vue?macro=true";
import { default as Section04Dq8XBQ7265U0g_45_Mlm_lULz0eYuOt1sz6WH0cosL0Meta } from "/vercel/path0/pages/theory-of-giving/Section.vue?macro=true";
import { default as indexHdGdkBzaEGCPb_M7VfkaLAbWUaxYpnNztVw0fYa7ezMMeta } from "/vercel/path0/pages/who-we-are/index.vue?macro=true";
import { default as UserCardngpzoT6CHZThKF2Y9viC1tmdLr_45BSxT7H3_gtLkvjXUMeta } from "/vercel/path0/pages/who-we-are/UserCard.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about/index.vue")
  },
  {
    name: "anniversary",
    path: "/anniversary",
    meta: anniversaryJxDx_VjKiHwrKcczmTcyKALoGEIAH_45RK9FV158kBQQ0Meta || {},
    component: () => import("/vercel/path0/pages/anniversary.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: auth7OMn_45RoyumEofPwA4BlS_45YlbPnH7bLXKV0RJxD4jYUkMeta || {},
    component: () => import("/vercel/path0/pages/auth.vue")
  },
  {
    name: "campaigns",
    path: "/campaigns",
    meta: indexA3gAnHil6NAbO9fQFT05eCXtOspgdDuHHS7lEwCry_45IMeta || {},
    component: () => import("/vercel/path0/pages/campaigns/index.vue")
  },
  {
    name: "campaigns-ocean-voyages-institute",
    path: "/campaigns/ocean-voyages-institute",
    component: () => import("/vercel/path0/pages/campaigns/ocean-voyages-institute/index.vue")
  },
  {
    name: "campaigns-plastic-fischer",
    path: "/campaigns/plastic-fischer",
    component: () => import("/vercel/path0/pages/campaigns/plastic-fischer/index.vue")
  },
  {
    name: "campaigns-reefgen",
    path: "/campaigns/reefgen",
    meta: indexNl4mlJgQEPALeTfb7Eq9dpBir8jLm_57tMscgytuwpQMeta || {},
    component: () => import("/vercel/path0/pages/campaigns/reefgen/index.vue")
  },
  {
    name: "campaigns-trees-for-the-future",
    path: "/campaigns/trees-for-the-future",
    component: () => import("/vercel/path0/pages/campaigns/trees-for-the-future/index.vue")
  },
  {
    name: "campaigns-yellow-rooms",
    path: "/campaigns/yellow-rooms",
    component: () => import("/vercel/path0/pages/campaigns/yellow-rooms/index.vue")
  },
  {
    name: "expowest",
    path: "/expowest",
    component: () => import("/vercel/path0/pages/expowest.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "for-business-FAQ",
    path: "/for-business/FAQ",
    component: () => import("/vercel/path0/pages/for-business/FAQ.vue")
  },
  {
    name: "for-business-Hero",
    path: "/for-business/Hero",
    component: () => import("/vercel/path0/pages/for-business/Hero.vue")
  },
  {
    name: "for-business",
    path: "/for-business",
    component: () => import("/vercel/path0/pages/for-business/index.vue")
  },
  {
    name: "for-business-Partners",
    path: "/for-business/Partners",
    component: () => import("/vercel/path0/pages/for-business/Partners.vue")
  },
  {
    name: "for-business-Pricing",
    path: "/for-business/Pricing",
    component: () => import("/vercel/path0/pages/for-business/Pricing.vue")
  },
  {
    name: "for-business-Reviews",
    path: "/for-business/Reviews",
    component: () => import("/vercel/path0/pages/for-business/Reviews.vue")
  },
  {
    name: "for-business-sign-up",
    path: "/for-business/sign-up",
    meta: sign_45up3OgRvvKN_4502KlXPM96ZcUNxrSFlY2nQS9DkEdZyICYUMeta || {},
    component: () => import("/vercel/path0/pages/for-business/sign-up.vue")
  },
  {
    name: "for-business-Step1",
    path: "/for-business/Step1",
    component: () => import("/vercel/path0/pages/for-business/Step1.vue")
  },
  {
    name: "for-business-Step2",
    path: "/for-business/Step2",
    component: () => import("/vercel/path0/pages/for-business/Step2.vue")
  },
  {
    name: "for-business-Step3",
    path: "/for-business/Step3",
    component: () => import("/vercel/path0/pages/for-business/Step3.vue")
  },
  {
    name: "for-business-Step4",
    path: "/for-business/Step4",
    component: () => import("/vercel/path0/pages/for-business/Step4.vue")
  },
  {
    name: "for-business-TreeCounter",
    path: "/for-business/TreeCounter",
    component: () => import("/vercel/path0/pages/for-business/TreeCounter.vue")
  },
  {
    name: "for-business-VettedBy",
    path: "/for-business/VettedBy",
    component: () => import("/vercel/path0/pages/for-business/VettedBy.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordYJWD0lcAfYaJTH90StowtOH8csR4uDHd8I_HNp6CgXIMeta || {},
    component: () => import("/vercel/path0/pages/forgot-password.vue")
  },
  {
    name: "gift-redeem",
    path: "/gift/redeem",
    meta: redeemsdjaTQH3bMFg6gUvS0n8IyXYKYm33p4_BJ3V81QE73EMeta || {},
    component: () => import("/vercel/path0/pages/gift/redeem.vue")
  },
  {
    name: "gift-signup",
    path: "/gift/signup",
    meta: signupqntZ9tkIFeCR_ugKwjQRxUi7toQXWEnYCqGXVvXOSaQMeta || {},
    component: () => import("/vercel/path0/pages/gift/signup.vue")
  },
  {
    name: "how-it-works-Card",
    path: "/how-it-works/Card",
    component: () => import("/vercel/path0/pages/how-it-works/Card.vue")
  },
  {
    name: "how-it-works-CarouselCard",
    path: "/how-it-works/CarouselCard",
    component: () => import("/vercel/path0/pages/how-it-works/CarouselCard.vue")
  },
  {
    name: "how-it-works-FutureShift",
    path: "/how-it-works/FutureShift",
    component: () => import("/vercel/path0/pages/how-it-works/FutureShift.vue")
  },
  {
    name: "how-it-works-GlobalImpact",
    path: "/how-it-works/GlobalImpact",
    component: () => import("/vercel/path0/pages/how-it-works/GlobalImpact.vue")
  },
  {
    name: "how-it-works-HeroSection",
    path: "/how-it-works/HeroSection",
    component: () => import("/vercel/path0/pages/how-it-works/HeroSection.vue")
  },
  {
    name: "how-it-works-ImpactStories",
    path: "/how-it-works/ImpactStories",
    component: () => import("/vercel/path0/pages/how-it-works/ImpactStories.vue")
  },
  {
    name: "how-it-works",
    path: "/how-it-works",
    component: () => import("/vercel/path0/pages/how-it-works/index.vue")
  },
  {
    name: "how-it-works-InspireGiveWin",
    path: "/how-it-works/InspireGiveWin",
    component: () => import("/vercel/path0/pages/how-it-works/InspireGiveWin.vue")
  },
  {
    name: "how-it-works-MicroChange",
    path: "/how-it-works/MicroChange",
    component: () => import("/vercel/path0/pages/how-it-works/MicroChange.vue")
  },
  {
    name: "how-it-works-ScalingHope",
    path: "/how-it-works/ScalingHope",
    component: () => import("/vercel/path0/pages/how-it-works/ScalingHope.vue")
  },
  {
    name: "how-it-works-TrackImpact",
    path: "/how-it-works/TrackImpact",
    component: () => import("/vercel/path0/pages/how-it-works/TrackImpact.vue")
  },
  {
    name: "humanitys-checklist-CallToAction",
    path: "/humanitys-checklist/CallToAction",
    component: () => import("/vercel/path0/pages/humanitys-checklist/CallToAction.vue")
  },
  {
    name: "humanitys-checklist-Checklist",
    path: "/humanitys-checklist/Checklist",
    component: () => import("/vercel/path0/pages/humanitys-checklist/Checklist.vue")
  },
  {
    name: "humanitys-checklist-DisplayTitle",
    path: "/humanitys-checklist/DisplayTitle",
    component: () => import("/vercel/path0/pages/humanitys-checklist/DisplayTitle.vue")
  },
  {
    name: "humanitys-checklist-Goals",
    path: "/humanitys-checklist/Goals",
    component: () => import("/vercel/path0/pages/humanitys-checklist/Goals.vue")
  },
  {
    name: "humanitys-checklist",
    path: "/humanitys-checklist",
    component: () => import("/vercel/path0/pages/humanitys-checklist/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    meta: joinlzZNx4kYUvWvSr9GzAn4W1_45yBETs9NGnIIisdjZ5xqkMeta || {},
    component: () => import("/vercel/path0/pages/join.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginyiubrZraL9EDD3J38Z_45ftLpCUym9OoYm1Hb9453rWnMMeta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/vercel/path0/pages/logout.vue")
  },
  {
    name: "members-id",
    path: "/members/:id()",
    meta: _91id_93gbXUxvXgRSAznUYatWjGYow5bg2E_45VeSbgxmsZoxIfIMeta || {},
    component: () => import("/vercel/path0/pages/members/[id].vue")
  },
  {
    name: "neckerpickleball",
    path: "/neckerpickleball",
    meta: neckerpickleball2ng3NtrbniHlHbUsLhi9CS_RbnOKTVT8lpo4_45I3YrnAMeta || {},
    component: () => import("/vercel/path0/pages/neckerpickleball.vue")
  },
  {
    name: _91id_93yMyoCFo1CoQRR1MapoYViqREYKRc0PECMHa2TP1M180Meta?.name,
    path: "/nfc-shirt/:id()",
    meta: _91id_93yMyoCFo1CoQRR1MapoYViqREYKRc0PECMHa2TP1M180Meta || {},
    component: () => import("/vercel/path0/pages/nfc-shirt/[id].vue"),
    children: [
  {
    name: "nfc-shirt-id-confirmation",
    path: "confirmation",
    component: () => import("/vercel/path0/pages/nfc-shirt/[id]/confirmation.vue")
  },
  {
    name: "nfc-shirt-id-donate",
    path: "donate",
    component: () => import("/vercel/path0/pages/nfc-shirt/[id]/donate.vue")
  },
  {
    name: "nfc-shirt-id",
    path: "",
    meta: index7Tropa8ghn8Yl_45fkarAHYijZiBgBOrqkyZKiYXhj_45mcMeta || {},
    component: () => import("/vercel/path0/pages/nfc-shirt/[id]/index.vue")
  },
  {
    name: "nfc-shirt-id-login",
    path: "login",
    meta: loginDOSqWjfZwENiMtTjQVMg_H8K2RRfLmYIPoL_iRWh8Z4Meta || {},
    component: () => import("/vercel/path0/pages/nfc-shirt/[id]/login.vue")
  },
  {
    name: "nfc-shirt-id-signup",
    path: "signup",
    meta: signupqIyeI3v14440z9N9vRWVOrfEc82wfbQtRmuOvt7alsQMeta || {},
    component: () => import("/vercel/path0/pages/nfc-shirt/[id]/signup.vue")
  },
  {
    name: "nfc-shirt-id-success",
    path: "success",
    meta: successyE3yfYgiQgS_sDK2aHAidirU8Bfa_VwETmGCThXASwUMeta || {},
    component: () => import("/vercel/path0/pages/nfc-shirt/[id]/success.vue")
  }
]
  },
  {
    name: "nfc-shirt",
    path: "/nfc-shirt",
    component: () => import("/vercel/path0/pages/nfc-shirt/index.vue")
  },
  {
    name: "partners-slug",
    path: "/partners/:slug()",
    meta: _91slug_930Dzq7DThBr5bZSi0wlDP_skHEdEw_45IVEmbJE_58abJcMeta || {},
    component: () => import("/vercel/path0/pages/partners/[slug].vue")
  },
  {
    name: "password-recovery",
    path: "/password-recovery",
    component: () => import("/vercel/path0/pages/password-recovery.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexrJwxQCynhxpb3nvssQCtS1bmH8PjRlT9veyZZLlYGwsMeta || {},
    component: () => import("/vercel/path0/pages/profile/index.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upAJio_5B8Q3uHds_45pvV9dCL_rHlF1ZjrGCGXnPECopOIMeta || {},
    component: () => import("/vercel/path0/pages/sign-up.vue")
  },
  {
    name: "theory-of-giving",
    path: "/theory-of-giving",
    component: () => import("/vercel/path0/pages/theory-of-giving/index.vue")
  },
  {
    name: "theory-of-giving-Section",
    path: "/theory-of-giving/Section",
    component: () => import("/vercel/path0/pages/theory-of-giving/Section.vue")
  },
  {
    name: "who-we-are",
    path: "/who-we-are",
    component: () => import("/vercel/path0/pages/who-we-are/index.vue")
  },
  {
    name: "who-we-are-UserCard",
    path: "/who-we-are/UserCard",
    component: () => import("/vercel/path0/pages/who-we-are/UserCard.vue")
  }
]