import { defineAsyncComponent } from 'vue'
export default {
  default: defineAsyncComponent(() => import("/vercel/path0/layouts/default.vue").then(m => m.default || m)),
  login: defineAsyncComponent(() => import("/vercel/path0/layouts/login.vue").then(m => m.default || m)),
  naked: defineAsyncComponent(() => import("/vercel/path0/layouts/naked.vue").then(m => m.default || m)),
  "nfc-layout-demo": defineAsyncComponent(() => import("/vercel/path0/layouts/nfc-layout-demo.vue").then(m => m.default || m)),
  "nfc-layout": defineAsyncComponent(() => import("/vercel/path0/layouts/nfc-layout.vue").then(m => m.default || m)),
  profile: defineAsyncComponent(() => import("/vercel/path0/layouts/profile.vue").then(m => m.default || m)),
  "public-profile": defineAsyncComponent(() => import("/vercel/path0/layouts/public-profile.vue").then(m => m.default || m)),
  quiz: defineAsyncComponent(() => import("/vercel/path0/layouts/quiz.vue").then(m => m.default || m)),
  simple: defineAsyncComponent(() => import("/vercel/path0/layouts/simple.vue").then(m => m.default || m))
}