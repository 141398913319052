import posthog from "posthog-js";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const client = posthog.init(config.public.posthog.publicKey, {
    api_host: window.location.origin + "/ingest",
    ui_host: "https://us.posthog.com",
    person_profiles: "identified_only",
    capture_pageview: false,
    loaded: (posthog) => {
      if (config.public.environment !== "production") posthog.debug();
    },
  });

  const router = useRouter();
  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture(
        "$pageview",
        {
          current_url: to.fullPath,
        },
        { transport: "sendBeacon" },
      );
    });
  });

  return {
    provide: {
      posthog: client,
    },
  };
});
