import { theme } from "#tailwind-config";
import type { NFCType } from "./types";
import { Campaign } from "~/lib/campaigns";

export const useCurrentNfcId = () =>
  computed(() => useRoute().params.id as string);

export function useNfcName(type: MaybeRefOrGetter<NFCType>) {
  return computed<string>(() => {
    const nfcType = toValue(type);

    switch (nfcType) {
      case "trees":
        return "Tree Shirt";
      case "seas":
        return "Sea Shirt";
      case "logo":
        return "We Shirt";
      case "logo-blue":
        return "We Shirt Ultra";
    }
  });
}

export function useNfcLogo(type: MaybeRefOrGetter<NFCType>, animated = true) {
  return computed(() => {
    const nfcType = toValue(type);

    switch (nfcType) {
      case "trees":
        return animated
          ? "/images/nfc-shirt/tree-shirt-logo-animated.png"
          : "/images/nfc-shirt/tree-shirt-logo.png";
      case "seas":
        return animated
          ? "/images/nfc-shirt/sea-shirt-logo-animated.png"
          : "/images/nfc-shirt/sea-shirt-logo.png";
      case "logo":
        return "/images/nfc-shirt/we-shirt-logo.png";
      case "logo-blue":
        return "/images/nfc-shirt/we-shirt-blue-logo.png";
    }
  });
}

export function useNfcTypeColors(nfcType: MaybeRefOrGetter<NFCType>) {
  const theme = useTheme();

  return computed<{ text: string; textShadow: string }>(() => {
    const type = toValue(nfcType);

    switch (type) {
      case "trees":
        return {
          text: theme.colors["green-lawn"],
          textShadow: theme.colors["orange-flame"],
        };
      case "seas":
        return {
          text: theme.colors["blue-pale-azure"],
          textShadow: theme.colors["blue-deep"],
        };
      case "logo":
      case "logo-blue":
        return {
          text: "#FFF",
          textShadow: "#000",
        };
    }
  });
}

export function useNfcCampaignColors(campaignId: MaybeRefOrGetter<number>) {
  return computed(() => {
    const id = toValue(campaignId);

    switch (id) {
      case Campaign.TFTF:
        return {
          text: theme.colors["green-lawn"],
          textShadow: theme.colors["orange-flame"],
        };
      case Campaign.PF:
        return {
          text: theme.colors["blue-pale-azure"],
          textShadow: theme.colors["blue-deep"],
        };
      case Campaign.SARTHAK:
        return {
          text: theme.colors["yellow-aureolin"],
          textShadow: "#000",
        };
      default:
        throw new Error("Invalid campaign");
    }
  });
}

export function useNfcCampaignAction(campaignId: MaybeRefOrGetter<number>) {
  return computed(() => {
    const id = toValue(campaignId);

    switch (id) {
      case Campaign.TFTF:
        return {
          cta: "Plant More Trees!",
          unit: "Trees",
          verb: "Planted",
        };
      case Campaign.PF:
        return {
          cta: "Remove More Plastic!",
          unit: "Lbs of ocean plastic",
          verb: "Removed",
        };
      case Campaign.SARTHAK:
        return {
          cta: "Fund More!",
          unit: "Days of Education",
          verb: "Funded",
        };
      default:
        throw new Error("Invalid campaign");
    }
  });
}

export function useNfcShirtIcon(type: MaybeRefOrGetter<NFCType>) {
  return computed(() => {
    const nfcType = toValue(type);

    switch (nfcType) {
      case "trees":
        return "/images/profile-shirts-badges/tree-shirt.png";
      case "seas":
        return "/images/profile-shirts-badges/sea-shirt.png";
      case "logo":
        return "/images/profile-shirts-badges/we-shirt.png";
      case "logo-blue":
        return "/images/profile-shirts-badges/we-shirt-ultra.png";
    }
  });
}

export function useNfcMetrics(
  nfc: MaybeRefOrGetter<{
    campaignId: number | null;

    claim?: {
      ownMetrics: Record<number, { impact: number; donated: number }>;
      additionalMetrics: Record<
        number,
        { impact: number; donated: number; contributors: number }
      >;
    };
  }>,
) {
  return computed(() => {
    const value = toValue(nfc);

    if (!value.claim || !value.campaignId)
      return {
        impact: 0,
        donated: 0,
        additional: {
          impact: 0,
          donated: 0,
          contributors: 0,
        },
        total: {
          impact: 0,
          donated: 0,
        },
      };

    const ownMetrics = value.claim.ownMetrics[value.campaignId];
    const additionalMetrics = value.claim.additionalMetrics[value.campaignId];

    const v = {
      ...ownMetrics,
      additional: additionalMetrics,
      total: {
        impact: ownMetrics.impact + additionalMetrics.impact,
        donated: ownMetrics.donated + additionalMetrics.donated,
      },
    };

    return v;
  });
}
