import { useQuery } from "@tanstack/vue-query";
import { useApi } from "~/composables/useApi";
import camelize from "camelcase-keys";
import type { AdditionalMetrics, Metrics, NFCType } from "./types";

export const nfcQueries = {
  detail: (nfcId: MaybeRefOrGetter<string>) => ({
    queryKey: ["nfc", nfcId, "detail"],
    queryFn: async () => {
      const api = useApi();
      const data = await api<NfcResponse>(`/v1/nfc/${toValue(nfcId)}`);
      return camelize(data, { deep: true });
    },
  }),
};

export const useNfcQuery = (id: string) => {
  return useQuery(nfcQueries.detail(id));
};

type Claim = {
  id: number;
  nfc_id: string;
  member_id: number;
  member_name: string;
  position: number;
  own_metrics: Record<number, Metrics>;
  additional_metrics: Record<number, AdditionalMetrics>;
  progress: {
    level: number;
    experience_earned_in_current_level: number;
    progress_to_next_level: number;
    total_experience: number;
    total_experience_to_next_level: number;
  };
  created_at: string;
  updated_at: string;
};

type NfcResponse = {
  id: string;
  campaign_id: number | null;
  dollar_value: number;
  donation_amount: number;
  type: NFCType;
  upgrade: {
    rarity: "ultra-rare" | "rare" | "epic";
    dollar_amount: number;
  } | null;
  claim?: Claim;
};
