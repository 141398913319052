import revive_payload_client_qsIndxaBxjwORp9lWlEumdUmrfLOKyKN7wEuqVlOoPA from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0_m_crabz5mgo2rlkihzi6xzp77uui/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PBjTXs_LHUPVn2__vX0CFQ0p2z4toxE41hW4UlRWOTs from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0_m_crabz5mgo2rlkihzi6xzp77uui/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6LzYyTryVeRnVzayP_ou0_KF9IdHmqC_xjSNrWyimpo from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0_m_crabz5mgo2rlkihzi6xzp77uui/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jrUPTz8WMYdixYhD4Iqt2OF1yFlyNdBpo1Wq_NukxJ8 from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.1.6_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_4FqIZ7BO8nQsokBAEGUb_NMVXGsIl_oSk8SpD5Js6yI from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0_m_crabz5mgo2rlkihzi6xzp77uui/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_wdOSxLhyQLyrlDGQjczZKV3pVKJ7nr09bNqztIE_7HI from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0_m_crabz5mgo2rlkihzi6xzp77uui/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Qdl8KpvzseRdIGi2e7kw5nasBxS9RcRbM63QPDIVk8w from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0_m_crabz5mgo2rlkihzi6xzp77uui/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4hIlmUO10tfXRW1Gm7XLC4nhXfZGbVxBni1lFYJ0GXY from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0_m_crabz5mgo2rlkihzi6xzp77uui/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_O96A93CpKmXP1Bgd5yzl45UdG5ORzU0dIkcavHDXUNI from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_Y_Ij_P_c4wYx7u3NHWrTgW64s_xGfy44PcEFvnj_zrc from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0_m_crabz5mgo2rlkihzi6xzp77uui/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_pBW1f8vLHkhhsTCSG_kWDJKjUS41Tp0JQz8_u6KUA8s from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@9.6.0_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1_@opente_u3kacf5wk6suen765ihak66p6y/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4 from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sounds_LDTSKNhoZyoIT02Yg2tZA3Mr1YiV2MUDWcsXYHsofbs from "/vercel/path0/node_modules/.pnpm/@vueuse+sound@2.1.3_vue@3.5.13_typescript@5.7.3_/node_modules/@vueuse/sound/dist/runtime/plugins/sounds.ts";
import motion_cchDrYqLnL_nZKsnOrRt86I3PszGaMG1BqGieJ6wi0s from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import plugin_favOVlntZryKtd6iohp1IumMo0FBYqFQ6v1sHx886GE from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.2_@types+node@22.13.4_jiti@2.4.2_sass@1.85.0_terser_cckwlyt6k4hmxzivqcjyadnkp4/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import vimeo_player_7c_PP_99oDNj38lRvrTyx_IlhO57p4vj_TBg_wXUSUs from "/vercel/path0/plugins/vimeo-player.js";
import plugin_6rXvAfk232Yp5N1GeZd6Eb6KhHeBckI6KdBr_wn9Jqw from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue_ldbosh2iqzb36n5r2qz37gqipm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import api_client_6ysWxWxD3qNQbfBeUydH25RKLpQgrlTKcByMcbebP5k from "/vercel/path0/plugins/api-client.ts";
import posthog_client_2oml6AGWFDC739NVqwRmPEFqVxgr0onP_SqUR62_ALw from "/vercel/path0/plugins/posthog.client.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/vercel/path0/plugins/sentry.client.ts";
import toastify_gvD_WvN2fE_LoHdr8xlsjTyi7m3Gd43gufuenTH_fKk from "/vercel/path0/plugins/toastify.ts";
import vue_query_GNtWx11mSO94O_Ctd2OG8SaawZMBzi1ne17Edj_iIOc from "/vercel/path0/plugins/vue-query.ts";
export default [
  revive_payload_client_qsIndxaBxjwORp9lWlEumdUmrfLOKyKN7wEuqVlOoPA,
  unhead_PBjTXs_LHUPVn2__vX0CFQ0p2z4toxE41hW4UlRWOTs,
  router_6LzYyTryVeRnVzayP_ou0_KF9IdHmqC_xjSNrWyimpo,
  _0_siteConfig_jrUPTz8WMYdixYhD4Iqt2OF1yFlyNdBpo1Wq_NukxJ8,
  payload_client_4FqIZ7BO8nQsokBAEGUb_NMVXGsIl_oSk8SpD5Js6yI,
  navigation_repaint_client_wdOSxLhyQLyrlDGQjczZKV3pVKJ7nr09bNqztIE_7HI,
  check_outdated_build_client_Qdl8KpvzseRdIGi2e7kw5nasBxS9RcRbM63QPDIVk8w,
  chunk_reload_client_4hIlmUO10tfXRW1Gm7XLC4nhXfZGbVxBni1lFYJ0GXY,
  plugin_vue3_O96A93CpKmXP1Bgd5yzl45UdG5ORzU0dIkcavHDXUNI,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_Y_Ij_P_c4wYx7u3NHWrTgW64s_xGfy44PcEFvnj_zrc,
  sentry_client_pBW1f8vLHkhhsTCSG_kWDJKjUS41Tp0JQz8_u6KUA8s,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk,
  plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4,
  sounds_LDTSKNhoZyoIT02Yg2tZA3Mr1YiV2MUDWcsXYHsofbs,
  motion_cchDrYqLnL_nZKsnOrRt86I3PszGaMG1BqGieJ6wi0s,
  plugin_favOVlntZryKtd6iohp1IumMo0FBYqFQ6v1sHx886GE,
  vimeo_player_7c_PP_99oDNj38lRvrTyx_IlhO57p4vj_TBg_wXUSUs,
  plugin_6rXvAfk232Yp5N1GeZd6Eb6KhHeBckI6KdBr_wn9Jqw,
  api_client_6ysWxWxD3qNQbfBeUydH25RKLpQgrlTKcByMcbebP5k,
  posthog_client_2oml6AGWFDC739NVqwRmPEFqVxgr0onP_SqUR62_ALw,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  toastify_gvD_WvN2fE_LoHdr8xlsjTyi7m3Gd43gufuenTH_fKk,
  vue_query_GNtWx11mSO94O_Ctd2OG8SaawZMBzi1ne17Edj_iIOc
]