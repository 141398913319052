import { useApi } from "~/composables/useApi";
import camelize from "camelcase-keys";

export const giftCardQueries = {
  detail: (code: MaybeRefOrGetter<string>) => ({
    queryKey: ["gift-card", code],
    queryFn: async () => {
      const api = useApi();
      const data = await api<ImpactGiftResponse>(
        `/v1/impact-gifts/${toValue(code)}`,
      );
      return camelize(data, { deep: true });
    },
  }),
};

type ImpactGiftResponse = {
  id: number;
  code: string;
  gifter_name: string;
  amount: number;
  status: "available" | "redeemed";
  campaign_id: number | null;
  giftee_name: string;
  redeemed_at: string | null;
  created_at: string;
  updated_at: string;
};
