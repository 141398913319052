import { useAccessToken } from "~/domains/member/composables";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const $api = $fetch.create({
    baseURL: config.public.apiURL,
    onRequest({ options }) {
      const token = useAccessToken();
      if (token.value) {
        options.headers.set("Authorization", `Bearer ${token.value}`);
      }
      if (!options.headers.has("Accept")) {
        options.headers.set("Accept", "application/json");
      }
    },
  });

  return {
    provide: {
      api: $api,
    },
  };
});
