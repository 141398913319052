import { useMutation, useQueryClient } from "@tanstack/vue-query";

import { memberQueries } from "./queries";
import type { Campaign } from "~/lib/campaigns";
import { transactionQueries } from "~/domains/transaction/queries";
import { subscriptionQueries } from "~/domains/subscription/queries";
import { useSelf } from "./composables";
import { nfcQueries } from "../nfc/queries";

export function useSetDatastoreKey(memberId: MaybeRef<number>) {
  const queryClient = useQueryClient();
  const api = useApi();

  return useMutation({
    mutationKey: ["datastore", memberId],
    mutationFn: async (params: { key: string; value: any }) => {
      queryClient.setQueryData(
        memberQueries.datastore(memberId).queryKey,
        (old) => {
          return {
            ...old,
            [params.key]: { ...params.value },
          };
        },
      );
      await api(`/v1/members/${toValue(memberId)}/datastore`, {
        method: "POST",
        body: {
          key: params.key,
          data: params.value,
        },
      });
    },
  });
}

export function useCreateDonationMutation() {
  const queryClient = useQueryClient();
  const { data: self } = useSelf();

  return useMutation({
    mutationFn: async (params: {
      setupIntentId: string;
      campaignId: Campaign;
      oneTime: boolean;
      amount: number;
      processingFee: number;
      tip: number;
      nfcId?: string;
      isACH?: boolean;
    }) => {
      const api = useApi();

      const response = await api("/v1/members/donate", {
        method: "PUT",
        body: {
          nfc_id: params.nfcId,
          payment: {
            processor: "stripe",
            payload: {
              setup_intent_id: params.setupIntentId,
            },
            details: {
              campaign_id: params.campaignId,
              one_time: params.oneTime,
              is_ach: params.isACH ?? false,
              amount: params.amount,
              processing_fee:
                self.value?.type === "partner" ? 0 : params.processingFee,
              tip: self.value?.type === "partner" ? 0 : params.tip,
            },
          },
        },
      });

      return response;
    },
    onSuccess: (_, variables) => {
      if (variables.nfcId) {
        queryClient.invalidateQueries({
          queryKey: nfcQueries.detail(variables.nfcId).queryKey,
        });
      }
      return Promise.all([
        queryClient.invalidateQueries(memberQueries.self()),
        queryClient.invalidateQueries(
          transactionQueries.list({
            memberId: self.value!.id,
          }),
        ),
        queryClient.invalidateQueries(
          subscriptionQueries.list({
            memberId: self.value!.id,
          }),
        ),
      ]);
    },
  });
}
