<script setup lang="ts">
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";

const { error } = defineProps({
  error: Object,
});

const handleError = () => clearError({ redirect: "/" });
</script>

<template>
  <div
    class="flex h-screen w-full flex-col items-center justify-center gap-9 bg-gradient-to-b from-ocean to-white"
  >
    <div
      v-if="error && error.statusCode && error.statusCode === 404"
      class="flex items-center"
    >
      <span
        class="text-shadow-sm font-Kontesa text-[120px] font-bold leading-3 text-white lg:text-[290px]"
      >
        4
      </span>
      <NuxtImg
        src="/images/not-found-page/emoji.png"
        sizes="50vw md:20vw"
        class="w-[90px] lg:w-[221px]"
        alt="not found emoji"
      />
      <span
        class="text-shadow-sm font-Kontesa text-[120px] font-bold leading-3 text-white lg:text-[290px]"
      >
        4
      </span>
    </div>
    <h2 v-else-if="error && error.statusCode" class="text-h5 text-navy">
      {{ error.statusCode }}
    </h2>

    <p
      v-if="error && error.statusCode && error.statusCode === 404"
      class="max-w-[23ch] text-center font-Kontesa text-h3-mobile font-bold text-navy lg:text-h3"
    >
      Somehow, it exists...and it doesn’t at the same time.
    </p>

    <Button variant="primary-flat" @click="handleError">
      Back to homepage
    </Button>
    <VueQueryDevtools />
  </div>
</template>
