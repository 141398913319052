const _sans = ["ui-sans-serif","system-ui","sans-serif","\"Apple Color Emoji\"","\"Segoe UI Emoji\"","\"Segoe UI Symbol\"","\"Noto Color Emoji\""]
const _serif = ["ui-serif","Georgia","Cambria","\"Times New Roman\"","Times","serif"]
const _mono = ["ui-monospace","SFMono-Regular","Menlo","Monaco","Consolas","\"Liberation Mono\"","\"Courier New\"","monospace"]
const _Aeonik = ["Aeonik","sans-serif"]
const _KelsiFill = ["Kelsi-fill","sans-serif"]
const _Kelsi = ["Kelsi","sans-serif"]
const _Kontesa = ["Kontesa","sans-serif"]
const _SubwayTicker = ["SubwayTicker","sans-serif"]
const _Agrandir = ["Agrandir","sans-serif"]
const config = { "sans": _sans, "serif": _serif, "mono": _mono, "Aeonik": _Aeonik, "KelsiFill": _KelsiFill, "Kelsi": _Kelsi, "Kontesa": _Kontesa, "SubwayTicker": _SubwayTicker, "Agrandir": _Agrandir,  }
export { config as default, _sans, _serif, _mono, _Aeonik, _KelsiFill, _Kelsi, _Kontesa, _SubwayTicker, _Agrandir }