const _xs = ["0.75rem",{"lineHeight":"1rem"}]
const _sm = ["0.875rem",{"lineHeight":"1.25rem"}]
const _base = ["1rem",{"lineHeight":"1.5rem"}]
const _lg = ["1.125rem",{"lineHeight":"1.75rem"}]
const _xl = ["1.25rem",{"lineHeight":"1.75rem"}]
const _2xl = ["1.5rem",{"lineHeight":"2rem"}]
const _3xl = ["1.875rem",{"lineHeight":"2.25rem"}]
const _4xl = ["2.25rem",{"lineHeight":"2.5rem"}]
const _5xl = ["3rem",{"lineHeight":"1"}]
const _6xl = ["3.75rem",{"lineHeight":"1"}]
const _7xl = ["4.5rem",{"lineHeight":"1"}]
const _8xl = ["6rem",{"lineHeight":"1"}]
const _9xl = ["8rem",{"lineHeight":"1"}]
const _h1 = ["145px",{"lineHeight":"0.78em","letterSpacing":"-3.35px"}]
const _h2 = ["84px",{"lineHeight":"0.8em","letterSpacing":"-1.12px"}]
const _h3 = ["48px",{"lineHeight":"0.8em","letterSpacing":"-0.48px"}]
const _h4 = ["32px",{"lineHeight":"0.9em","letterSpacing":"-0.64px"}]
const _h5 = ["21px",{"lineHeight":"0.9em","letterSpacing":"-0.42px"}]
const _p1 = ["15px",{"lineHeight":"1.2em","letterSpacing":"-0.45px"}]
const _p2 = ["12px",{"lineHeight":"1.2em","letterSpacing":"-0.12px"}]
const config = { "xs": _xs, "sm": _sm, "base": _base, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl, "7xl": _7xl, "8xl": _8xl, "9xl": _9xl, "h1": _h1, "h2": _h2, "h3": _h3, "h4": _h4, "h5": _h5, "p1": _p1, "p2": _p2, "h1-mobile": ["52px",{"lineHeight":"0.8em","letterSpacing":"-1.56px"}], "h2-mobile": ["36px",{"lineHeight":"0.8em","letterSpacing":"-0.36px"}], "h3-mobile": ["28px",{"lineHeight":"0.9em","letterSpacing":"-0.56px"}], "h4-mobile": ["21px",{"lineHeight":"0.9em","letterSpacing":"-0.42px"}], "h5-mobile": ["15px",{"lineHeight":"0.9em","letterSpacing":"-0.3px"}], "p1-mobile": ["15px",{"lineHeight":"1.2em","letterSpacing":"-0.45px"}], "p2-mobile": ["12px",{"lineHeight":"1.2em","letterSpacing":"-0.12px"}],  }
export { config as default, _xs, _sm, _base, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl, _7xl, _8xl, _9xl, _h1, _h2, _h3, _h4, _h5, _p1, _p2 }